import React, { useState } from "react"
import axios from "axios"
import CheckCircleOutlineIcon from "../images/svg/check_circle_outline.svg"
import MailOutlineIcon from "../images/svg/mail_outline.svg"
import PersonOutlineIcon from "../images/svg/person_outline.svg"

const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    submitSucceeded: null,
  })

  const handleServerResponse = submitSucceeded => {
    setServerState({
      submitting: false,
      submitSucceeded: submitSucceeded,
    })
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    setServerState({ submitting: true, submitSucceeded: null })

    const form = e.target
    axios({
      method: "post",
      url: "https://getform.io/f/c0e79b00-a1ab-49d9-8f9c-215e3ae79f26",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true)
      })
      .catch(r => {
        handleServerResponse(false)
      })
  }
  return (
    <div>
      {serverState.submitSucceeded === null && (
        <div>
          <div className="content has-text-centered">
            <div className="container form-header">
              <p>
                Use the form below if you have any questions or want to get in
                touch.
              </p>
            </div>
          </div>
          <fieldset disabled={serverState.submitting}>
            <form onSubmit={handleOnSubmit} autoComplete="off">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="name" className="label">
                    Name
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input className="input" type="text" name="name" />
                      <span className="icon is-small is-left">
                        <PersonOutlineIcon />
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input "
                        type="email"
                        name="email"
                        required
                      />
                      <span className="icon is-small is-left">
                        <MailOutlineIcon />
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="message" className="label">
                    Message
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="How we can help you?"
                        name="message"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label"></div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button className="button is-primary">
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      )}

      {serverState.submitSucceeded !== null &&
        serverState.submitSucceeded === true && (
          <div className="content form-feedback has-text-centered">
            <div>
              <CheckCircleOutlineIcon className="icon-success" />
              <div>Thank you. We will contact you as soon as possible.</div>
            </div>
          </div>
        )}

      {serverState.submitSucceeded !== null &&
        serverState.submitSucceeded === false && (
          <div className="content form-feedback has-text-centered">
            <div>
              <p>Something went wrong. Please try again.</p>
            </div>
          </div>
        )}
    </div>
  )
}

export default ContactForm
