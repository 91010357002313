import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import Hero from "../components/hero"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <Hero title="Greetings," subtitle="Let's work together." />

    <section className="section contact">
      <div className="columns">
        <div className="column is-1"></div>
        <div className="column">
          <ContactForm />
        </div>
        <div className="column is-1"></div>
      </div>
    </section>

    <div className="content has-text-centered">
      <section className="section contact-footer">
        <div>
          <p>
            Want to contact us directly by mail? Send us a message at{" "}
            <a href="mailto:info@codestack.be">info@codestack.be</a>
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Contact
